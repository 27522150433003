.form__control {
    height: 48px;
    width: 100%;
    background-color: var(--light-input);
    overflow: hidden;
    transition: background-color 0.5s ease;
    margin: 8px 0;
    &:hover {
      opacity: 0.9;
    }
    .input {
      // background-color: white;
    //   border: 1px solid  #B3BAC5;
      width: 100%;
      height: 48px;
      outline: none;
      border-radius:6px ;
      border: none;
      padding: 10px 16px;
      // margin-top:10px ;
      // margin-bottom:10px ;
      // color: var(--neutral-mid);
    }
    &::placeholder{
      background-color: aqua;
    }
  }
  