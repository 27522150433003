.heading {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  color: var(--neutral-dark);
}

.sub__heading {
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  color: var(--neutral-dark);
  line-height: 1.5;
}

.small__text {
  line-height: 1.5;
  font-size: 12px;
  color: var(--neutral-mid);
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .heading {
    font-size: 1.5rem;
  }

  .sub__heading {
    font-size: 0.75rem;
  }

  .small__text {
    line-height: 1.5;
    font-size: 12px;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .heading {
    font-size: 1.75rem;
  }
  .sub__heading {
    font-size: 0.875rem;
  }

  .small__text {
    line-height: 1.5;
    font-size: 12px;
    text-align: center;
  }
}
