.btn {
  font-weight: 500;
  cursor: pointer;
  // width: 100%;
  transition: all 0.5s ease;
  font-family: var(--font-family);
  font-size: 16px;
  max-height: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 6px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  .loading__container,
  .btn__icon__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 480px) {
  .btn {
    font-size: 14px;
    .loading__container,
    .btn__icon__container {
      div {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}
@media only screen and (max-width: 350px) {
  .btn {
    font-size: 14px;
    .loading__container,
    .btn__icon__container {
      div {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .btn {
    font-size: 14px;
    .loading__container,
    .btn__icon__container {
      div {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}

